export enum Role {
    L1Moderator = 'l1_moderator',
    L2Moderator = 'l2_moderator',
    Admin = 'admin',
    Developer = 'developer',
    None = 'none',
}


export const MODERATOR_ADMIN_ROLES: [Role, Role, Role] = [Role.L1Moderator, Role.L2Moderator, Role.Admin];
export const ADMIN_ROLE: [Role] = [Role.Admin]
export const MODERATOR_ROLES: [Role, Role] = [Role.L1Moderator, Role.L2Moderator];

export const DEVELOPER_ADMIN_ROLES: [Role, Role] = [Role.Developer, Role.Admin];
export const ALL_ROLES: [Role, Role, Role, Role] = [Role.Developer, Role.Admin, Role.L1Moderator, Role.L2Moderator];

export const RATING_OPTIONS = ["High", "Medium", "Low", "None"];
export const DISABILITY_CATEGORIES = ["L1", "L2", "L3"];

export const enum ProfileImageOptions {
    ProfilePhotoFaceDoesntMatchVideo = "Face in the photo does not match the video",
    PhotoFiltered = "Photo is filtered",
    NudityOrSexualContent = "Nudity or Sexual Content",
    FakeEngagementOrIllegalActivitiesOrViolence = "Fake Engagement / Illegal Activities / Graphic Violence",
    ChildSafety = "Child Safety",
    Political = "Elections / Political",
}

export const PROFILE_IMAGE_OPTIONS = [
    ProfileImageOptions.ProfilePhotoFaceDoesntMatchVideo,
    ProfileImageOptions.PhotoFiltered,
    ProfileImageOptions.NudityOrSexualContent,
    ProfileImageOptions.FakeEngagementOrIllegalActivitiesOrViolence,
    ProfileImageOptions.ChildSafety,
    ProfileImageOptions.Political,
];

export const enum ModerationFlags {
    NudityOrSexualContent = "Nudity & Sexual Content",
    VulgarLanguage = "Vulgar Language",
    FakeEngagementOrIllegalActivitiesOrViolence = "Fake Engagement / Illegal Activities / Graphic Violence",
    ChildSafety = "Child Safety",
    Impersonation = "Impersonation",
    HateSpeech = "Hate Speech",
    Political = "Elections / Political",
    DisrespectDisability = "Disrespecting Disability",
    None = "None",
}

export const MODERATION_FLAGS = [
    ModerationFlags.NudityOrSexualContent,
    ModerationFlags.VulgarLanguage,
    ModerationFlags.FakeEngagementOrIllegalActivitiesOrViolence,
    ModerationFlags.ChildSafety,
    ModerationFlags.Impersonation,
    ModerationFlags.HateSpeech,
    ModerationFlags.Political,
    ModerationFlags.DisrespectDisability,
    ModerationFlags.None,
];

export const CHECK_OPTIONS = ["Yes", "No"];

export const enum ModerationOptions {
    FaceNotVisibleInVideo = "Face is not visible in the video",
    SpeechNotAudibleInAudio = "Speech is not audible in the audio",
    VideoFaceDoesntMatchSelfie = "Video face does not match the selfie photo",
    SpokeInNonEnglishLanguage = "Spoke in non-English language throughout the video",
    GenderNotSameAsProfile = "Gender is not the same as in profile",
    OtherReasons = "Other reasons",
    PoorVideoQuality = "Poor video quality",
    ModerationFlagsInText = "Moderation flags in one or more text",
    ModerationFlagInFathersName = "Moderation flag in father's name"
}

export const MODERATION_OPTIONS: string[] = [
    ModerationOptions.FaceNotVisibleInVideo,
    ModerationOptions.SpeechNotAudibleInAudio,
    ModerationOptions.VideoFaceDoesntMatchSelfie,
    ModerationOptions.SpokeInNonEnglishLanguage,
    ModerationOptions.GenderNotSameAsProfile,
    ModerationOptions.ModerationFlagInFathersName,
    ModerationOptions.ModerationFlagsInText,
    ModerationOptions.OtherReasons,
    ModerationOptions.PoorVideoQuality
];

export const REJECTION_MODERATION_OPTIONS: string[] = [
    // ModerationOptions.PoorVideoQuality,
    // ModerationOptions.SpeechNotAudibleInAudio,
    // ModerationOptions.FaceNotVisibleInVideo,
    ModerationOptions.GenderNotSameAsProfile,
];

export const ESCALATION_MODERATION_OPTIONS: string[] = [
    ModerationOptions.ModerationFlagsInText,
    ModerationOptions.VideoFaceDoesntMatchSelfie,
    ModerationOptions.OtherReasons,
];

export const UpdateModerationDataApi = "api/update_moderation_data"

export const UserStatus = "user_status"
export const UserId = "user_id"
export const ModeratorId = "moderator_id"
export const ModerationDataKey = "moderation_data"
export const ModeratorRole = "moderator_role"

export enum USER_STATUS {
    UnModerated = "un_moderated",
    Escalated = "escalated",
    Rejected = "rejected",
    Moderated = "moderated",
    Pending = "pending"
}

export enum VideoType {
    RapidFireVideo = "rapid_fire_video",
    KYCVideo = "kyc_video",
    RapidFireAudio = "rapid_fire_audio",
}

export const getVideoHeaderText = function (videoType: VideoType) {
    if (videoType === VideoType.RapidFireVideo) {
        return "Rapid-fire Video:";
    } else if (videoType === VideoType.KYCVideo) {
        return "KYC Video:";
    } else if (videoType === VideoType.RapidFireAudio) {
        return "Rapid-fire Audio:";
    }
    return ""
}
