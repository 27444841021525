import DropdownInput from "../labelledInput/labelledDropdown";
import {QuestionDetailsProps} from "../../model/interface";
import {DISABILITY_CATEGORIES, MODERATION_FLAGS, RATING_OPTIONS} from "../../model/constants";
import MultiSelectDropdownInput from "../labelledInput/labelledmultiSelectDropdown";

const QuestionDetails = ({moderationDetails, moderationData ,handleOnChange}: QuestionDetailsProps) => {
    return (
        <div className="flex gap-4 ">
            <DropdownInput
                label="Rating on the basis of looks"
                id="looks"
                value={moderationDetails.looks}
                onChange={(key, value) => handleOnChange(key, value)}
                options={RATING_OPTIONS}
            />
            <DropdownInput
                label="Rating on the basis of english"
                id="english"
                value={moderationDetails.english}
                onChange={(key, value) => handleOnChange(key, value)}
                options={RATING_OPTIONS}
            />
            {moderationData.disability_text!= null && moderationData.disability_text!= "None" && <DropdownInput
                label="Classification for the disability"
                id="disability_classification"
                value={moderationDetails.disability_classification}
                onChange={(key, value) => handleOnChange(key, value)}
                options={DISABILITY_CATEGORIES}
            />}
            <MultiSelectDropdownInput
                label="Moderation flags in Rapid Fire video"
                id="moderationFlags"
                multiselectValue={moderationDetails.moderationFlags}
                onChange={(key, value) => handleOnChange(key, value)}
                options={MODERATION_FLAGS}
            />
        </div>
    );
};

export default QuestionDetails;
