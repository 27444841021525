import React, {useEffect, useState} from "react";
import VideoLoader from "../common/videoLoader";
import ModerationForm from "./moderationForm";
import {VideoPlayerContextProvider} from "../../store/videoPlayerContext";
import Header from "../header/header";
import {useModerationDataContext} from "../../store/moderationDataContext";
import Heading from "../common/h3heading";
import apiService from "../../api/services/apiService";
import {
    ESCALATION_MODERATION_OPTIONS, getVideoHeaderText,
    ModerationDataKey,
    ModerationFlags,
    ModeratorId,
    ModeratorRole,
    REJECTION_MODERATION_OPTIONS,
    Role,
    USER_STATUS,
    UserId,
    UserStatus,
} from "../../model/constants";
import {ErrorDetail, ModerationDetails, RequestParams, UpdateModerationDetailsRequest} from "../../model/interface";
import AlertModal from "../common/alertModal";
import {useAuthContext} from "../../store/authContext";
import {getAgeFromDOB, haveCommonItems} from "../../utils/utils";
import Divider from "../common/divider";

const ModerationView = () => {
    const {moderationData, fetchError} = useModerationDataContext();
    const {user} = useAuthContext();
    const [responseError, setResponseError] = useState<ErrorDetail>({
        hasError: false,
        errorMessage: "",
    });
    const [moderationDetails, setModerationDetails] = useState<ModerationDetails>({
        looks: "",
        english: "",
        disability_classification:"",
        moderationDetails: [],
        profileImagesModerationDetails: new Map<string, string[]>(),
        otherModerationDetails: "",
        moderationFlags: [],
        moderationTimeTaken: "",
        moderationStartTime: "",
    });
    const handleInputChange = (key: string, value: string | string[] | Map<string, string[]>, asyncCallback?: (updatedState: ModerationDetails) => Promise<void>) => {
        if (!(key in moderationDetails)) {
            return;
        }
        setModerationDetails(prevState => {
            const updatedState = {
                ...prevState,
                [key]: value,
            };
            if (asyncCallback) {
                asyncCallback(updatedState);
            }
            return updatedState;
        });

    };

    const resetModerationDetails = () => {
        setModerationDetails({
            looks: "",
            english: "",
            disability_classification:"",
            moderationDetails: [],
            profileImagesModerationDetails: new Map<string, string[]>(),
            otherModerationDetails: "",
            moderationFlags: [],
            moderationTimeTaken: "",
            moderationStartTime: "",
        });
    }

    function getUserModerationStatus(details: ModerationDetails, role: string): USER_STATUS {

        if (details.moderationDetails.length > 0 && (haveCommonItems(details.moderationDetails, REJECTION_MODERATION_OPTIONS))) {
            return USER_STATUS.Rejected
        } else if ((details.moderationFlags.length > 0 && !details.moderationFlags.includes(ModerationFlags.None)) || (details.moderationDetails.length > 0 && haveCommonItems(details.moderationDetails, ESCALATION_MODERATION_OPTIONS))) {
            if (role == Role.L1Moderator) return USER_STATUS.Escalated
            else return USER_STATUS.Rejected
        } else {
            return USER_STATUS.Moderated
        }
    }

    function getRequestParams(updatedModerationDetails: ModerationDetails): RequestParams {
        const moderationRecord: { [key: string]: string } = {};
        const currentModerationDetails = updatedModerationDetails ?? moderationDetails;

        const updateReq: UpdateModerationDetailsRequest = {
            looks: currentModerationDetails.looks,
            english: currentModerationDetails.english,
            disability_classification: currentModerationDetails.disability_classification,
            moderationDetails: currentModerationDetails.moderationDetails,
            otherModerationDetails: currentModerationDetails.otherModerationDetails,
            profileImagesModerationDetails: JSON.stringify(Object.fromEntries(currentModerationDetails.profileImagesModerationDetails)),
            moderationFlags: currentModerationDetails.moderationFlags,
            moderationTimeTaken: currentModerationDetails.moderationTimeTaken,
            moderationStartTime: currentModerationDetails.moderationStartTime,
        }

        const moderatorRole = localStorage.getItem("userRole") ?? ""
        moderationRecord[ModeratorRole] = moderatorRole
        moderationRecord[ModerationDataKey] = JSON.stringify(updateReq);
        moderationRecord[UserStatus] = getUserModerationStatus(currentModerationDetails, moderatorRole);
        moderationRecord[UserId] = moderationData.user_id
        moderationRecord[ModeratorId] = user.user_id;

        return {
            record: moderationRecord,
        };
    }

    const submitModerationDetails = async (moderationDetails: ModerationDetails, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>): Promise<boolean> => {
        const params = getRequestParams(moderationDetails)
        const response = await apiService.updateModerationData(params);
        if (!response.success) {
            setResponseError({
                hasError: true,
                errorMessage: response.data,
            });
            setIsLoading(false);
            return false;
        }
        setIsLoading(false);
        return true;
    };
    const handleModalClose = () => {
        setResponseError({hasError: false, errorMessage: ""});
    };

    useEffect(() => {
        if (fetchError.hasError) {
            setResponseError(fetchError);
        }
    }, [fetchError]);

    return (
        <div className="w-full max-w-screen-2xl h-full">
            <Header/>
            <Divider/>
            {(moderationData !== undefined && moderationData != null) ? (
                <VideoPlayerContextProvider>
                    <div className="grid grid-cols-3 gap-4 w-full pl-5">
                        <div className="mt-10 space-y-4 items-center flex flex-col text-wine-100">
                            { moderationData.video_url !== "" &&
                                <>
                                    <Heading>{getVideoHeaderText(moderationData.video_type)}</Heading>
                                    <VideoLoader
                                        videoUrl={moderationData.video_url}
                                    />
                                </>
                            }
                            <div>
                                <div className="grid grid-rows-2 space-x-2 justify-items-center">
                                    <div className="flex flex-row space-x-1">
                                        {moderationData.user_name !== "" &&
                                            <>
                                                <Heading>{`Name: `}</Heading>
                                                <div className="capitalize text-wine-100 font-light text-xl">
                                                    {`${moderationData.user_name},`}
                                                </div>
                                            </>
                                        }
                                        {(moderationData.input_fathers_name !== "") &&
                                            <>
                                                <Heading>{`Father's Name: `}</Heading>
                                                <div className="capitalize text-wine-100 font-light text-xl">
                                                    {`${moderationData.input_fathers_name}`}
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="flex flex-row space-x-1">
                                        {moderationData.dob !== "" &&
                                            <>
                                                <Heading>{`Age:`}</Heading>
                                                <div className="capitalize text-wine-100 font-light text-xl">
                                                    {`${getAgeFromDOB(moderationData.dob)},`}
                                                </div>
                                            </>
                                        }
                                        {moderationData.gender !== "" &&
                                            <>
                                                <Heading>{`Gender:`}</Heading>
                                                <div className="capitalize text-wine-100 font-light text-xl">
                                                    {moderationData.gender}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {(moderationData.image_url !== "" || moderationData.id_image_url !== "") && (
                                <div className="flex w-full h-[315px]">
                                    { moderationData.image_url !== "" &&
                                        <div className="relative w-1/2 h-full">
                                            <img
                                                src={moderationData.image_url}
                                                alt="Hyper-verge Selfie"
                                                className="absolute inset-0 w-full h-full object-contain"
                                            />
                                            <div
                                                className="absolute bottom-0 left-0 right-0 bg-white text-center p-2 text-sm text-wine-100">
                                                {"Hyper-verge Selfie"}
                                            </div>
                                        </div>
                                    }
                                    { moderationData.id_image_url !== "" &&
                                        <div className="relative w-1/2 h-full">
                                            <img
                                                src={moderationData.id_image_url}
                                                alt="Govt. Id Image"
                                                className="absolute inset-0 w-full h-full object-contain"
                                            />
                                            <div
                                                className="absolute bottom-0 left-0 right-0 bg-white text-center p-2 text-sm text-wine-100">
                                                {"Govt. Id Image"}
                                            </div>
                                        </div>
                                    }
                                </div>
                            )}
                            <div>
                                {moderationData.user_id !== "" &&
                                    <Heading className="mb-0">{`UserId: ${moderationData.user_id}`}</Heading>
                                }
                            </div>
                        </div>
                        <div className="col-span-2 form-container">
                            <ModerationForm
                                moderationDetails={moderationDetails}
                                setModerationDetails={setModerationDetails}
                                handleInputChange={handleInputChange}
                                resetModerationDetails={resetModerationDetails}
                                submitModerationDetails={submitModerationDetails}
                            />
                        </div>
                    </div>
                </VideoPlayerContextProvider>
            ) : (
                <div className="flex items-center justify-center p-10 text-2xl"> NO DATA TO SHOW...</div>)
            }

            {responseError.hasError && (
                <AlertModal
                    onClose={handleModalClose}
                    header="Something went wrong"
                >
                    <p>{responseError.errorMessage}</p>
                </AlertModal>
            )}
        </div>
    );
};

export default ModerationView;
